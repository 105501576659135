import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';

const CustomButton = (props) => {
  return (
    <LoadingButton
      {...props}
      variant="outlined">
      {props.children}
    </LoadingButton>
  )
}
export default CustomButton;