
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { EventContext } from './App';
import api from "./api";
import Button from './components/button';
import GuestList from './components/guestList';
import { TitleBanner } from './components/titleBanner';
import { RSVP } from './constants';
import React from "react";



export const ViewGuests = () => {
  let { eventId, attendeeId, adminId } = useParams()
  const { event, setEvent } = useContext(EventContext)
  const [guests, setGuests] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentAttendee, setCurrentAttendee] = useState()
  const declineEvent = async () => {
    await api.updateAttendee(eventId, attendeeId, { rsvp: RSVP.DECLINED })
    const event = await api.getEvent(eventId)
    setEvent(event) //Todo: Not sure how to update eventlist. Now it's a get event every time someone accepts/declines.
    if (event.attendees) {
      setGuests(Object.values(event.attendees))
    }
  }

  const attendEvent = async () => {
    await api.updateAttendee(eventId, attendeeId, { rsvp: RSVP.ATTENDED })
    const event = await api.getEvent(eventId);
    setEvent(event)
    if (event.attendees) {
      setGuests(Object.values(event.attendees))
    }
  }
  useEffect(() => {
    const getEvent = async () => {
        setIsLoading(true)
        try {
          const event = await api.getEvent(eventId);
          setEvent(event)
          if (event.attendees) {
            setGuests(Object.values(event.attendees))
          }
          if (!adminId) {
            const res = await api.getAttendee(eventId, attendeeId)
            setCurrentAttendee(res)
          }
        } catch (e) {
          console.error("Did not successfully get event", e)
        } finally{
          setIsLoading(false)
        } 
    }
    getEvent()
  }, [eventId, adminId, attendeeId])

  if (isLoading) {
    return (<TitleBanner event={{ title: 'Just a moment' }} />)
  }
  if (!event) {
    return (<TitleBanner event={{ title: 'We couldn\'t find your event :(' }} />)
  }

  return (
    <Grid container spacing={2}
      flex-direction="column"
      align-items="center"
      justify-content="space-between"
      maxWidth={500}
      sx={{
        flex: 1
        // , backgroundColor: "pink"
      }}>
        {guests && <GuestList guests={guests} eventId={eventId} adminId={adminId} attendee={currentAttendee} />}
        <Grid container flexDirection="column" textAlign="center">
            <Grid item>
              {event && attendeeId &&
                <>
                  <Button align="center" sx={{ mr: 1, mb: 1 }} onClick={attendEvent}>Attend</Button>
                  <Button align="center" sx={{ mb: 1 }} onClick={declineEvent}>Decline</Button>
                </>
              }
            </Grid>
          </Grid>
    </Grid >
    
  );
}