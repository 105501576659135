import { useState } from 'react';
import React from "react";
import {DeleteConfirmationDialog}  from './deleteConfirmDialog';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { RSVP } from '../constants';


const GuestList = ({ guests, eventId, adminId, attendee }) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [selectedGuest, setSelectedGuest] = useState()

    const handleDeleteGuest = (guest) => {
      setSelectedGuest(guest)
      setDeleteDialogOpen(true);
    };
    const theme = useTheme();
    const getAttendeeListItem = (guest, attendee, adminId) => { //Todo not nicest handling of adminId

      if (adminId) {
        return (
          <ListItem key={`${guest.attendeeIdHash}`}
            secondaryAction={<IconButton edge="end" label="delete"
              onClick={() => handleDeleteGuest(guest)}>
              <CloseIcon icon-style={{ height: '25%', width: '25%' }} />
            </IconButton>}>
            <ListItemText primary={`${guest.name}`} />
          </ListItem>
        )
      } else if (attendee && guest.attendeeIdHash === attendee.attendeeIdHash) {
        return (
          <ListItem key={`${guest.attendeeIdHash}`}>
            <ListItemText
              // primaryTypographyProps={{ fontWeight:'bold'}}
              primary={`${guest.name} (me)`} />
          </ListItem>
        )
      } else {
        return (
          <ListItem key={`${guest.attendeeIdHash}`}>
            <ListItemText primary={`${guest.name}`} />
          </ListItem>
        )
      }
    }
  
    return (
      <>
        <List
            sx={{
            maxWidth: 360,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 500,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {[RSVP.ATTENDED, RSVP.DECLINED, RSVP.UNDECIDED].map((rsvp_header) => {
            return (
              <li key={`section-${rsvp_header}`}>
                <ul>
                  <ListSubheader sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.primary.main}}>
                    {`${rsvp_header}`}
                  </ListSubheader>
                  {guests.map((guest) => {
                    if (guest.rsvp === rsvp_header) {
                      return getAttendeeListItem(guest, attendee, adminId)
                    }
                    return null;
                  }
                  )}
                </ul>
              </li>
            );
          })
          }
        </List >
  
        <DeleteConfirmationDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} adminId={adminId} eventId={eventId} attendee={selectedGuest} />
      </>
    );
  }
  
  export default GuestList