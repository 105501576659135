import { ThemeProvider } from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json'; //todo
import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import CookieBanner from './components/cookieBanner';
import HelpDialog from './components/helpDialog';
import Snackbar from './components/snackbar';
import { ViewGuests } from './viewGuests';
const Home = lazy(() => import('./createEvent'));
const ViewEvent = lazy(() => import('./viewEvent'));
const JoinEvent = lazy(() => import('./joinEvent'));
export const KENVITE_URL = "https://kenvite.com";
TimeAgo.addDefaultLocale(en)

const font = "'Quicksand', sans-serif";
const theme = createTheme({
  palette: {
    background: {
      default: "#fAfafa"
    },
    type: 'light',
    primary: {
      main: '#4a55bf',
    },
    secondary: {
      main: '#ff8428',
    },
    success: {
      main: '#0FA3B1',
      dark: '#86D0D7',
    }
  },
  typography: {
    fontFamily: font,
  },
  text: {
    primary: '#333333',
  },
});



function Header() {
  const alphaTooltipText = `This page is under open alpha flag. Note that you might lose events that two weeks after they finished. Thank you for helping us test this product!`
  const [openHelp, setOpenHelp] = React.useState(false)
  return (
    <>
      <Container component="header" flex-direction="column" align-items="center" justify-content="center" maxWidth="lg" style={{ marginBottom: 100 }}>
        <Box display='inline-block' sx={{ textAlign: 'left', width: '50%' }}>
          <Link href="/" variant="h3" sx={{
            fontWeight: '550', letterSpacing: '-4px',
            textDecorationThickness: '3px',
            color: theme.palette.primary.main,
            fontFamily: 'Quicksand, sans-serif'
          }}>kenvite</Link>

        </Box>
        <Box display='inline-block' sx={{ textAlign: 'right', width: '50%' }}>

          <Tooltip title={alphaTooltipText} placement="bottom-end">
            <Typography variant="h6"
              display="inline" sx={{
                fontWeight: '550', letterSpacing: '-4px',
                color: theme.palette.secondary.main,
                fontFamily: 'Arial'
              }}>α</Typography>
          </Tooltip>
          <IconButton  onClick={() => setOpenHelp(true)}><HelpOutlineIcon /></IconButton>
          <HelpDialog open={openHelp} setOpen={setOpenHelp} />
        </Box>
      </Container>

    </>
  )
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © kenvite '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[200]
        //     : theme.palette.grey[800],
      }}
    >
      <Copyright />
    </Box>
  );
}
export const SnackbarContext = React.createContext({ open: false, setOpen: null, message: "", setMessage: null })
const SnackbarComponent = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState(false)
  return <SnackbarContext.Provider value={{ open, setOpen, message, setMessage }}>
    {children}
  </SnackbarContext.Provider>
}
// const SnackbarContext = React.createContext(false)

export const EventContext = React.createContext({ event: null, setEvent: null })
export const EventProvider = ({ children }) => {
  const [event, setEvent] = React.useState(null)
  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  )
}

const App = () => (
  <ThemeProvider theme={theme}>
    <EventProvider>
      <SnackbarComponent>
        <Snackbar autoHideDuration={1000}/>
        <CssBaseline />
        <Box
          sx={{
            // backgroundColor: 'green',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            margin:1
          }}>
          <Header />
          <Box
            component="main"
            sx={{
              // backgroundColor:"blue",
              flex: 1,
              justifyContent: 'center',
              maxWidth: 'md'
            }}
          >
            <CookieBanner/>
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/event/:eventId/admin/:adminId/guests" component={ViewGuests} />
                  <Route path="/event/:eventId/admin/:adminId" component={ViewEvent} />
                  <Route path="/event/:eventId/:attendeeId/guests" component={ViewGuests} />
                  <Route path="/event/:eventId/:attendeeId" component={ViewEvent} />
                  <Route path="/event/:eventId" component={JoinEvent} />
                </Switch>
              </Suspense>
            </Router>
          </Box>
          <Footer />
        </Box >
      </SnackbarComponent>
    </EventProvider>
  </ThemeProvider >
);


export default App;