import React, { useState, useEffect } from 'react';
import { Button} from '@material-ui/core';
import { Typography } from '@mui/material';


const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('acceptedCookies');

    if (acceptedCookies) {
      setShowBanner(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowBanner(false);
  };

  return (
    <div>
      {showBanner && (
        <div style={bannerStyle}>
          <Typography color='primary'>This site uses necessary cookies. By continuing to browse, you are agreeing to our use of cookies.</Typography>
          <Button padding="20px" variant="outlined" color='primary' onClick={handleAcceptCookies}>OK</Button>
        </div>
      )}
    </div>
  );
};

const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '10px',
  background: '#cacaca',
  textAlign: 'center',
  borderTop: '1px solid #ccc',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
};

export default CookieBanner;
