import { Typography } from '@mui/material'
import React from 'react'

export const TitleBanner = ({ event }) => {
  return (
    <>
      {event &&
        <Typography variant="h5" component="div" align="left">{event.title}</Typography>
      } </>
  )
}